.plans-container{
    margin-top: -2rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;
    position: relative;
}

.programs-header{
     gap: 6rem;   
}

.plans-blur-1{
    width: 23rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}

.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}

.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8rem;
}

.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 18rem;
}

.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.2);
}

.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plan:nth-child(3){
    font-size: 1rem;
    font-weight: bold;
}

.plan:nth-child(5){
    font-size: 0.8rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img{
    width: 1rem;
}

.plans>:nth-child(2)>svg{
    fill: white;
}

.plans>:nth-child(2)>button{
    color: red;
}

.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.right-t{
    flex: 1;
    position: relative;
}

.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 7.5rem;
    top: 1.5rem;
}

.right-t>:nth-child(1){
    position: absolute;
    width: 17rem; height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9;
}

.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    top: 4rem;
    right: 6rem;
    background: var(--planCard);
}

.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}

.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 768px){

    .programs-header{
        gap: 2rem;
    }

    .plans{
        flex-direction: column;
        gap: 4rem;
    }

    .plans>:nth-child(2){
        transform: none;
    }

    .plan{
        width: 14rem;
    }
}
